<template>
  <div id="app" v-cloak>
    <template v-if="is_dapp">
      <transition name="fade" mode="out-in" v-if="chainid == 1 || chainid == 56">
        <router-view v-if="show" />
      </transition>
      <div class="box" v-if="!show">
        <p>{{ $t('请切换到') }}</p>
      </div>
    </template>
    <template v-if="!is_dapp">
      <div class="box">{{ $t('请复制您的链接在DAPP浏览器访问') }}</div>
    </template>
  </div>
</template>
<script>
import Web3 from 'web3'
export default {
  name: "app",
  data() {
    return {
      is_dapp: true,
      chainid: null,
      show: true,
    };
  },
  mounted() {
    let self = this;
    window.localStorage.removeItem(`${process.env.VUE_APP_NAME}_token`);
    if (window.ethereum) {
      self.is_dapp = true
      window.ethereum.request({ method: "eth_chainId" }).then((res) => {
        console.warn('chain', Web3.utils.hexToNumberString(res));
        self.chainid = Web3.utils.hexToNumberString(res)
        self.$store.commit("SET_WALLET_CHAIN_ID", self.chainid)
        self.$metaMask.init().then(() => {
          let web3 = self.$metaMask.getWeb3();
          if (self.chainid == 1) {
            self.show = true
            self.$metaMask.getContract(web3, self.$eth_abis);
          } else if (self.chainid == 56) {
            self.show = true
            self.$metaMask.getContract(web3, self.$bsc_abis);
          } else {
            self.show = false
          }
        });
      })
    } else {
      self.is_dapp = false
    }
  },

};
</script>

<style lang="less">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.box {
  width: 100%;
  text-align: center;
  padding: 20px 50px;
  font-size: 16px;
  font-weight: 550;
}
</style>