<template>
  <div class='mine'>
    <nav-bar title="语言切换" left-arrow />
    <van-radio-group v-model="radio" @change="change">
      <van-cell-group>
        <van-cell title="中文繁体" :icon="require('../../../assets/img/c.png')" clickable @click="radio = 'zh'">
          <template #right-icon>
            <van-radio name="zh" checked-color="#F2B692" />
          </template>
        </van-cell>
        <van-cell title="English" :icon="require('../../../assets/img/e.png')" clickable @click="radio = 'en'">
          <template #right-icon>
            <van-radio name="en" checked-color="#F2B692" />
          </template>
        </van-cell>
        <van-cell title="日本語" :icon="require('../../../assets/img/r.png')" clickable @click="radio = 'ja'">
          <template #right-icon>
            <van-radio name="ja" checked-color="#F2B692" />
          </template>
        </van-cell>
        <van-cell title="한국어" :icon="require('../../../assets/img/h.png')" clickable @click="radio = 'ko'">
          <template #right-icon>
            <van-radio name="ko" checked-color="#F2B692" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio: '',
    }
  },
  created() {
    this.radio = this.local.get('lang')
  },
  methods: {
    change() {
      this.changeLang(this.radio)
    },
  },
}
</script>
<style lang='less' scoped>
.mine {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 10px 15px;
  /deep/.van-cell-group {
    background: none;
  }
  /deep/.van-cell {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background: #117948;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #B3A7AE;
    display: flex;
    align-items: center;
    .van-cell__left-icon {
      width: 25px;
      height: 25px;
      margin-right: 5px;
      display: flex;
      align-items: center;
    }
    .van-cell__title,
    .van-field__control {
      color: #fff;
      font-weight: 550;
    }
  }
}
</style>
