<template>
  <div class="indexbox">
    <nav-bar title="提现" right-text="记录" @click-right="router('withdrawallist')" left-arrow />
    <div class="header">
      <div class="left">
        <p>{{ $t('余额') }}</p>
        <p>{{ user.balance }}</p>
      </div>
      <img src="../../../assets/img/g.png" alt="" class="right">
    </div>
    <van-field v-model="num" type="text" clearable maxlength="20" :label="$t('提现数量')" :placeholder="$t('请输入提现数量')"
      :border=false />
    <!-- {{ $t('提现倍数') }}:{{info.multiple }} -->
    <p class="tit">{{ $t('手续费') }}:{{ info.fee }},{{ $t('最低提现数量') }}:{{ info.min_amount }}</p>
    <van-field v-model="getnum" readonly clearable :label="$t('实际到账')" :border=false />
    <van-field v-model="pass" :label="$t('安全密碼')" :placeholder="$t('请输入安全密碼')" maxlength="6" clearable type="password"
      :border=false />
    <van-button round color="linear-gradient(90deg, #FFE4CC 0%, #F9CEB0 48%, #F2B692 100%)" type="primary" size="large"
      :loading="loading" @click="chagne">{{ $t('提交') }}</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      info: {},
      num: "",
      pass: "",
      loading: false,
    };
  },
  computed: {
    getnum() {
      if (this.num && this.info.fee) {
        return (Number(this.num) - Number(this.info.fee)).toFixed(3)
      }
      return 0
    }
  },
  created() {
    this.getuser()
    this.getdata()
  },
  methods: {
    getdata() {
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.user = res.data;
        },
        tip: () => { },
      })

    },
    getuser() {
      this.$post({
        url: "/app/withdraw/getInfo",
        success: (res) => {
          this.info = res.data;
        },
        tip: () => { },
      });
    },
    chagne() {
      if (!this.num) return this.$toast(this.$t('请输入提现数量'))
      if (!this.pass) return this.$toast(this.$t('请输入安全密碼'))
      this.loading = true
      this.$post({
        url: "/app/withdraw/withdrawDo",
        data: {
          amount: this.num,
          password: this.pass,
        },
        success: (res) => {
          this.$toast({
            message: res.msg,
            onClose: () => {
              this.loading = false
              this.num = ''
              this.pass = ''
              this.getuser()
            }
          })
        },
        tip: () => {
          this.loading = false
        },
      })
    }
  },
};
</script>

<style lang="less" scoped="scoped">
.indexbox {
  width: 100%;
  padding: 10px 15px;
  .header {
    width: 100%;
    background: url('../../../assets/img/z.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 25px 20px;
    margin-bottom: 15px;
    .left {
      width: 60%;
      color: #117948;
      font-size: 16px;
      font-weight: 550;
      p {
        width: 100%;
        &:nth-child(2) {
          font-size: 30px;
          padding: 10px 0;
          span {
            font-size: 12px;
          }
        }
      }
      /deep/.van-button {
        width: 91px;
        background: none;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #117948;
      }
    }
    .right {
      width: 95px;
      height: 95px;
    }
  }
  /deep/.van-cell {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background: #117948;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #B3A7AE;
    display: flex;
    align-items: center;
    .van-cell__title,
    .van-field__control {
      color: #F6BE9D;
    }
  }
  .tit {
    width: 100%;
    padding: 0 0 10px;
    color: #F6BE9D;
    font-size: 14px;
    text-align: right;
  }
  /deep/.van-button {
    margin-top: 100px;
  }
}
</style>
