<template>
  <div class='indexbox'>
    <div class="headerbox">
      <nav-bar title="订单详情" left-arrow bg-color="none" left-color="#fff" title-color="#fff" :fixed="false" />
      <div class="statusbox">
        <p>{{ $t(topbox[info.status]) }}</p>
      </div>
    </div>
    <div class="bodybox">
      <div class="goodsbox">
        <img v-lazy="info.image" alt="">
        <div class="right">
          <p class="name">{{ info.goods_name }}</p>
          <p class="price">{{ info.pay_usdt }}<span>U</span></p>
        </div>
      </div>
      <div class="itembox">
        <p class="tit">{{ $t('价格明细') }}</p>
        <van-cell :title="$t('商品总价')" :value="`${info.pay_usdt}U`" :border="false" />
        <van-cell :title="$t('实际支付')" :value="`${info.pay_usdt}U`" :border="false" />
      </div>
      <div class="itembox">
        <p class="tit">{{ $t('订单信息') }}</p>
        <van-cell :title="$t('订单号')" :value="info.order_no" :border="false" />
        <van-cell :title="$t('创建时间')" :value="info.create_time" :border="false" />
        <van-cell v-if="info.status && info.status < 4" :title="$t('付款时间')" :value="info.pay_time" :border="false" />
        <van-cell v-if="info.status && info.status < 4" title="hash" :value="info.hash" :border="false" />
      </div>
    </div>

    <van-goods-action>
      <div style="width:50%"></div>
      <van-goods-action-button style="margin-right:2vw;" v-if="info.status == 0" :text="$t('取消订单')" :loading="loadingb"
        @click="cancel" color="#F2B692" />
      <van-goods-action-button v-if="info.status == 0" type="warning" :text="$t('立即支付')" :loading="loading"
        @click="payOrder" color="#FD7F1F" />
    </van-goods-action>
  </div>
</template>
<script>
export default {
  data() {
    return {
      topbox: { 0: '待付款', 1: '待确认', 2: '待核销', 3: '已完成', 4: '订单取消' },
      id: this.$route.query.id,
      info: {},
      address_info: {},
      show: false,
      password: '',
      loading: false,
      loadingb: false,
    };
  },
  filters: {
    get_down_time(times) {
      if (!times) return
      var date = new Date()
      var time = date.getTime()
      var endtime = new Date(times.replace(/-/g, "/")).getTime()
      return Number(endtime - time)
    },
  },
  computed: {
    is_chain() {
      return this.$store.state.wallet.chainId
    }
  },
  methods: {
    progressfun(data) {
      if (data > 0) {
        return Number(data / 5 * 100).toFixed(0)
      }
      return data
    },
    onCopy() {
      this.$toast({
        message: this.$t('复制成功'),
        duration: 400,
      })
    },
    onError() {
      this.$toast({
        message: this.$t('复制失败'),
        duration: 400,
      })
    },
    getdata() {
      this.$post({
        url: '/app/orders/info',
        loading: true,
        data: {
          order_id: this.id,
        },
        success: (res) => {
          this.info = res.data
          if (this.info.status == 0) {
            this.getpaydata()
          }
        },
        tip: () => { },
      })
    },
    getpaydata() {
      this.$post({
        url: '/app/member/getInfo',
        success: (res) => {
          this.address_info = res.data
        },
        tip: () => { },
      })
    },
    // 取消订单
    cancel() {
      this.loadingb = true
      this.$post({
        url: '/app/orders/cancelOrder',
        loading: true,
        data: {
          order_id: this.id,
        },
        success: (res) => {
          this.$toast.success({
            message: this.$t("成功"),
            onClose: () => {
              this.loadingb = false;
              this.getdata()
            },
          });
        },
        tip: () => {
          this.loadingb = false
        },
      })
    },
    // 支付
    payOrder() {
      let inAddress = ''
      let precision = ''
      if (this.is_chain == 1) {
        inAddress = this.address_info.etc_recharge
        precision = 'mwei'
      } else if (this.is_chain == 56) {
        inAddress = this.address_info.bsc_recharge
        precision = 'ether'
      }
      if (inAddress) {
        this.loading = true
        this.transfer(this.id, inAddress, precision);
      } else {
        this.loading = false
        this.$toast({
          message: this.$t("系统未设置收款钱包地址")
        })
      }
    },
    transfer(order_id, inAddress, precision) {
      let that = this;
      that.$nextTick(() => {
        that.blockFun
          .transfer("usdt", String(that.info.pay_usdt), inAddress, precision, false, that.is_chain)
          .then((hash) => {
            that.paySuccess(order_id, hash);
          })
          .catch((err) => {
            console.log(err);
            that.loading = false;
          });
      });
    },
    paySuccess(order_id, hash) {
      this.$post({
        url: '/app/orders/paySuccess',
        data: {
          order_id,
          hash,
        },
        success: () => {
          this.$toast.success({
            message: this.$t("成功"),
            onClose: () => {
              this.loading = false;
              this.$router.replace('paysuccess')
            },
          });
        },
        tip: () => {
          this.loading = false;
        },
      })
    }
  },
  created() {
    console.warn('chainId', this.is_chain);
    window.scrollTo(0, 0)
    this.getdata()
  },
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  padding: 0 0 70px;
  .headerbox {
    width: 100%;
    .statusbox {
      width: 100%;
      padding: 20px 15px;
      color: #FFFFFF;
      font-size: 22px;
      font-weight: 550;
    }
  }
  .bodybox {
    width: 100%;
    padding: 10px 15px;
    .goodsbox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px;
      background: rgba(11, 11, 11, .2);
      border-radius: 10px;
      margin-bottom: 15px;
      >img {
        width: 85px;
        height: 85px;
        border-radius: 6px;
      }
      .right {
        width: 70%;
        min-height: 85px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        .name {
          width: 100%;
          color: #F6BE9D;
          font-size: 15px;
          font-weight: 550;
          line-height: 1.5;
        }
        .price {
          width: 100%;
          color: #F6BE9D;
          font-size: 18px;
          font-weight: 550;
          span {
            font-size: 12px;
          }
        }
      }
    }
    .itembox {
      width: 100%;
      background: rgba(11, 11, 11, .2);
      border-radius: 10px;
      margin-bottom: 15px;
      padding: 0 0 5px;
      .tit {
        width: 100%;
        color: #F6BE9D;
        font-size: 17px;
        font-weight: 550;
        padding: 15px 15px 5px;
      }
      /deep/.van-cell {
        background: none;
        display: flex;
        align-items: center;
        color: #F6BE9D;
        .van-cell__value {
          color: #F6BE9D;
        }
      }
      .copy {
        width: 15px;
        height: 15px;
        margin-left: 6px;
      }
    }
  }
  /deep/.van-goods-action {
    background: transparent;
  }
}
</style>
