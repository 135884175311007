<template>
  <div class='indexbox'>
    <nav-bar title="" />
    <img src="../../../assets/img/d.png" alt="">
    <p>{{ $t('支付成功') }}</p>
    <p class="msg">{{ $t('您已完成支付') }}</p>
    <van-button type="default" to="list">{{ $t('知道了') }}</van-button>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() { },
  mounted() { },
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 30px;
  img {
    width: 130px;
    height: 130px;
  }
  p {
    color: #F6BE9D;
    font-size: 17px;
    font-weight: 550;
  }
  .msg {
    font-weight: 500;
    font-size: 13px;
    color: #FCDABF;
    padding: 10px 0 30px;
  }
  /deep/.van-button {
    width: 134px;
    background: #117948;
    border-radius: 40px;
    border: 1px solid #F6BE9D;
    .van-button__text {
      color: #F6BE9D;
    }
  }
}
</style>