<template>
  <div class='indexbox'>
    <nav-bar title="钱包明细" left-arrow />
    <div class="headerbox">
      <div class="header">
        <div class="left">
          <p>{{ $t('余额') }}</p>
          <p>{{ info.balance }}</p>
          <van-button to="withdrawal" size="small" type="primary">{{ $t('提现') }}</van-button>
        </div>
        <img src="../../../assets/img/g.png" alt="" class="right">
      </div>
    </div>
    <div class="titbox">
      <div class="tits">{{ $t('余额变动明细') }}</div>
      <van-list v-model="loading" :finished="finished" :finished-text="`${$t('没有更多了')}`" @load="onLoad" :offset="0">
        <div class="list" v-for="val in list" :key="val.id">
          <div>
            <div class="dest">{{ val.remark }}</div>
            <div class="time">{{ val.create_time }}</div>
          </div>
          <span class="num">{{ Number(val.amount).toFixed(2) }}</span>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.info = res.data;
        },
        tip: () => { },
      })
    },
    onLoad() {
      var page = this.page++
      this.$post({
        url: "/app/balance_log/pageList",
        data: {
          page: page,
          balance_type: 1
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  padding: 0 0 50px;
  .headerbox {
    width: 100%;
    padding: 10px 15px;
    .header {
      width: 100%;
      background: url('../../../assets/img/z.png') no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 45px 0 45px 30px;
      .left {
        width: 60%;
        color: #117948;
        font-size: 16px;
        font-weight: 550;
        p {
          width: 100%;
          &:nth-child(2) {
            font-size: 30px;
            padding: 10px 0;
            span {
              font-size: 12px;
            }
          }
        }
        /deep/.van-button {
          width: 91px;
          background: none;
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #117948;
        }
      }
      .right {
        width: 95px;
        height: 95px;
      }
    }
  }
  .titbox {
    width: 92%;
    margin: 0 auto;
    padding: 0 15px;
    background: #117948;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #B3A7AE;
    .tits {
      width: 100%;
      color: #F6BE9D;
      font-size: 15px;
      font-weight: 550;
      padding: 10px 0;
    }
    .list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding: 10px 0;
      >div {
        max-width: 58%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .dest {
          font-size: 13px;
          font-weight: 550;
          color: #FCDABF;
        }
        .time {
          margin-top: 5px;
          font-size: 12px;
          color: #FCDABF;
        }
      }
      .num {
        max-width: 40%;
        font-size: 16px;
        color: #F6BE9D;
        font-weight: 550;
      }
    }
  }
}
</style>