<template>
  <div class='indexbox'>
    <nav-bar title="明细" left-arrow />
    <div class="header">
      <div class="left">
        <p>{{ $t('LP代币') }}</p>
        <p>{{ user.lp || 0 }}</p>
      </div>
      <img src="../../../assets/img/g.png" alt="" class="right">
    </div>
    <div class="nodata" v-if="user.lp_status == 0">
      <img src="../../../assets/img/f.png" alt="">
      <p>{{ $t('钱包等待投放，暂时无法使用') }}</p>
    </div>
    <div class="titbox" v-if="user.lp_status == 1">
      <van-list v-model="loading" :finished="finished" :finished-text="`${$t('没有更多了')}`" @load="onLoad" :offset="0">
        <div class="list" v-for="val in list" :key="val.id">
          <div class="dest" @click="copyData(val.hash)">
            <span>{{ val.hash }}</span>
            <img src="../../../assets/img/la.png" alt="">
          </div>
          <p class="num">{{ val.amount }}</p>
          <div class="time">{{ $t('空投') }}:{{ val.create_time }}</div>
          <div class="time">{{ $t('赠送') }}:{{ val.put_in_time }}</div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {},
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.user = res.data;
        },
        tip: () => { },
      })

    },
    onLoad() {
      var page = this.page++
      this.$post({
        url: "/app/lp_log/pageList",
        data: {
          page: page,
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  padding: 10px 15px;
  .header {
    width: 100%;
    background: url('../../../assets/img/z.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 25px 20px;
    margin-bottom: 15px;
    .left {
      width: 60%;
      color: #117948;
      font-size: 16px;
      font-weight: 550;
      p {
        width: 100%;
        &:nth-child(2) {
          font-size: 30px;
          padding: 10px 0;
          span {
            font-size: 12px;
          }
        }
      }
      /deep/.van-button {
        width: 91px;
        background: none;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #117948;
      }
    }
    .right {
      width: 95px;
      height: 95px;
    }
  }
  .nodata {
    width: 100%;
    padding: 30px 0 0;
    height: 40vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    img {
      width: 152px;
      height: 152px;
      margin-bottom: 10px;
    }
    p {
      width: 80%;
      text-align: center;
      color: #FCDABF;
      font-size: 15px;
      font-weight: 550;
    }
  }
  .titbox {
    width: 100%;
    padding: 0 15px;
    background: #117948;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #B3A7AE;
    .tits {
      width: 100%;
      color: #F6BE9D;
      font-size: 15px;
      font-weight: 550;
      padding: 10px 0;
    }
    .list {
      width: 100%;
      margin-bottom: 10px;
      padding: 10px 0;
      .dest {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          max-width: 90%;
          font-size: 14px;
          font-weight: 550;
          color: #FCDABF;
        }
        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }
      .num {
        width: 100%;
        padding: 5px 0 0;
        font-size: 16px;
        color: #F6BE9D;
        font-weight: 550;
      }
      .time {
        width: 100%;
        margin-top: 5px;
        font-size: 14px;
        color: #FCDABF;
        font-weight: 550;
      }
    }
  }
}
</style>