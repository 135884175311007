function bsc_loadAbis() {
  let locales = []
  locales = require.context('./bsc/', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const obj = {}
  if (locales.length == 0) {
    return
  }
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      obj[locale] = locales(key)
      obj[locale].name = locale
    }
  })
  return obj
}

function eth_loadAbis() {
  let locales = []
  locales = require.context('./eth/', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const obj = {}
  if (locales.length == 0) {
    return
  }
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      obj[locale] = locales(key)
      obj[locale].name = locale
    }
  })
  return obj
}


const bsc_abis = bsc_loadAbis();
const eth_abis = eth_loadAbis();


const install = Vue => {
  if (install.installed) {
    return
  }
  install.installed = true
  Object.defineProperties(Vue.prototype, {
    $bsc_abis: {
      get() {
        return bsc_abis
      }
    },
    $eth_abis: {
      get() {
        return eth_abis
      }
    }
  })
}

export default install
