<template>
  <div class='indexbox'>
    <nav-bar title="详情" left-arrow />
    <div class="bodybox">
      <div class="title">{{ info.title }}</div>
      <div class="time">{{ info.create_time }}</div>
      <div class="content htmlcontentbox" v-html="info.content"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      info: {}
    }
  },
  methods: {
    getdata() {
      this.$toast.loading({
        forbidClick: true,
      })
      this.$post({
        url: '/app/notice/info',
        data: {
          id: this.$route.query.id
        },
        success: res => {
          this.$toast.clear()
          this.info = res.data
        },
        tip: () => { }
      })
    }
  },
  created() {
    window.scrollTo(0, 0)
    this.getdata()
  },
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  .bodybox {
    width: 100%;
    padding: 10px 15px;
    .title {
      width: 100%;
      line-height: 1.6;
      font-size: 20px;
      color: #fff;
      font-weight: 550;
    }
    .time {
      width: 100%;
      font-size: 12px;
      color: #FFf;
      line-height: 3;
    }
    .content {
      width: 100%;
      font-size: 13px;
      color: #Fff;
      line-height: 1.5;
    }
  }
}
</style>
