<template>
  <div class="minebox">
    <nav-bar title="个人" />
    <div class="userbox">
      <img v-if="token" src="../../assets/img/logo.png" alt="">
      <div class="user">
        <div class="btnbox" v-if="!token">
          <van-button size="small" type="primary" color="#FFE4CC" :loading="loading" @click="getaddress">{{
            $t('地址验证登录') }}</van-button>
        </div>
        <p class="accound" v-else>{{ info.account }}</p>
        <p class="uid" v-if="token">
          <span @click="copyData(info.uid)">UID：{{ info.uid }}</span>
          <span v-if="info.level_name">{{ info.level_name }}</span>
        </p>
      </div>
    </div>
    <template v-if="!token">
      <div class="numbox">
        <div @click="toast($t('请地址验证登录'))">
          <p>0</p>
          <p>{{ $t('收益钱包') }}</p>
        </div>
        <div>
          <p>0</p>
          <p>{{ $t('LP代币') }}</p>
        </div>
      </div>
    </template>
    <template v-if="token">
      <div class="numbox">
        <div @click="router('wallet')">
          <p>{{ info.balance || 0 }}</p>
          <p>{{ $t('收益钱包') }}</p>
        </div>
        <div @click="router('lplist')">
          <p>{{ info.lp || 0 }}</p>
          <p>{{ $t('LP代币') }}</p>
        </div>
      </div>
      <div class="item">
        <van-cell :title="$t('我的订单')" to="list" :icon="require('../../assets/img/q.png')" is-link :border=false />
        <van-cell :title="$t('我的社区')" to="myteam" :icon="require('../../assets/img/s.png')" is-link :border=false />
        <van-cell :title="$t('分享好友')" to="invite" :icon="require('../../assets/img/t.png')" is-link :border=false />
        <van-cell :title="$t('语言切换')" to="changlang" :icon="require('../../assets/img/u.png')" is-link :border=false />
        <van-cell :title="$t('客服中心')" to="feedback" :icon="require('../../assets/img/v.png')" is-link :border=false />
        <van-cell :title="$t('密码设置')" to="setting" :icon="require('../../assets/img/w.png')" is-link :border=false />
      </div>
    </template>

    <van-dialog v-model="show" :title="$t('暂未绑定上级')" show-cancel-button confirm-button-color="#ee0a24"
      :before-close="change">
      <p class="tit">{{ $t('请输入上级') }}</p>
      <van-field v-model="pid_address" :placeholder="$t('请输入')" clearable />
    </van-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      token: '',
      address: '',
      invite_code: this.$route.query.code,
      loading: false,
      chain: 0,//链 1 以太坊 2 币安链
      show: false,
      pid_address: '',
    }
  },
  computed: {
    chainId() {
      return this.$store.state.wallet.chainId
    }
  },
  created() {
    console.warn('chainId', this.chainId);
    if (this.local.get("token")) {
      this.token = this.local.get("token")
      this.getuser()
    }
    if (this.$route.query.code) {
      this.local.set("code", this.$route.query.code)
    }
    if (this.local.get("code")) {
      this.invite_code = this.local.get("code")
    }
  },
  methods: {
    getaddress() {
      this.$nextTick(() => {
        if (!this.token) {
          var time;
          time = setInterval(() => {
            this.address = this.$store.state.wallet.account;
            console.warn('login_address', this.address);
            console.warn('login_chainId', this.chainId);
            if (this.chainId == 1) {
              this.chain = 1
            } else if (this.chainId == 56) {
              this.chain = 2
            }
            clearInterval(time);
            this.loginapi()
          }, 1000);
        }
      });
    },
    loginapi() {
      this.loading = true
      this.$post({
        loading: true,
        url: "/app/auth/login",
        data: {
          account: this.address,
          invite_code: this.invite_code || '',
          chain: this.chain,
        },
        success: (res) => {
          this.local.set("token", res.data.token)
          this.invite_code = ''
          this.local.del("code")
          this.token = res.data.token
          this.$store.state.is_chain = this.chain;
          this.loading = false
          this.getuser();
        },
        tip: () => {
          this.loading = false
        },
      });
    },
    getuser() {
      this.$post({
        url: "/app/member/memberInfo",
        success: (res) => {
          this.info = res.data;
          if (this.info.pid == 0) {
            // this.show = true
          }
        },
        tip: () => { },
      });
    },
    change(action, done) {
      if (action == "confirm") {
        if (!this.pid_address) {
          this.$toast(this.$t("请输入上级"));
          done(false);
          return;
        }
        this.$post({
          url: "/app/member/bindParent",
          data: {
            inviteCode: this.pid_address
          },
          success: (res) => {
            done();
          },
          tip: () => {
            done(false);
          },
        });
      } else {
        done();
      }
    }
  },
}
</script>
<style scoped lang="less">
.minebox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 15px;
  .userbox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0 0;
    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      border-radius: 20px;
      object-fit: contain;
    }
    .user {
      flex: 1;
      .btnbox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /deep/.van-button {
          padding: 0 25px;
          .van-button__text {
            font-size: 22px;
          }
        }
      }
      .accound {
        width: 100%;
        color: #F6BE9D;
        font-size: 16px;
        font-weight: 550;
      }
      .uid {
        width: 100%;
        color: #fff;
        font-size: 14px;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .numbox {
    width: 100%;
    background: rgba(112, 112, 112, .5);
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px 0;
    >div {
      width: 50%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        color: #fff;
        font-size: 16px;
        &:nth-child(1) {
          font-size: 22px;
          font-weight: 550;
        }
      }
    }
  }
  .item {
    background: rgba(28, 114, 79, 0.9);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #207D57;
    padding: 10px 0;
    /deep/.van-cell {
      background: none;
      display: flex;
      align-items: center;
      .van-icon {
        display: flex;
        align-items: center;
      }
      .van-cell__title {
        color: #F6BE9D;
        font-size: 14px;
        padding: 0 15px;
      }
      .van-cell__right-icon {
        color: #fff;
      }
    }
  }
}
/deep/.van-dialog {
  background: linear-gradient(180deg, #3A353C 0%, #262231 100%);
  .van-dialog__content {
    padding: 20px;
    .tit {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
    .van-cell {
      margin-top: 20px;
      background: #1C1429;
      background: rgba(11, 11, 11, .2);
      border-radius: 10px;
    }
  }
  .van-button--default {
    background-color: #1C1429 !important;
  }
  .van-dialog__cancel {
    .van-button__text {
      color: #fff !important;
    }
  }
}
/deep/ .van-dialog__confirm {
  .van-button__text {
    color: #ee0a24 !important;
  }
}
</style>
