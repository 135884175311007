<template>
  <div class="index">
    <van-sticky>
      <nav-bar title="订单" :fixed="false" left-arrow />
      <van-tabs @click="onClick" v-model="active" :broder="false" line-height="2" color="#F6BE9D"
        title-active-color="#F6BE9D" title-inactive-color='#F6BE9D'>
        <van-tab :title="$t(val.tit)" v-for="val in topbox" :key="val.ind">
        </van-tab>
      </van-tabs>
    </van-sticky>
    <van-list v-model="loading" :finished="finished" :finished-text="`${$t('没有更多了')}`" @load="onLoad">
      <div class="listbox">
        <div class="item" v-for="val in list" :key="val.id" @click="godel(val)">
          <div class="topbox">
            <p>{{ val.order_no }}</p>
            <p>{{ $t(topbox[val.status].tit) }}</p>
          </div>
          <div class="goodsbox">
            <img v-lazy="val.image" alt="">
            <div class="right">
              <p class="name one">{{ val.goods_name }}</p>
              <p class="price">{{ val.pay_usdt }}<span>U</span></p>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      topbox: [{ tit: '待付款', ind: 0 }, { tit: '待确认', ind: 1 }, { tit: '待核销', ind: 2 }, { tit: '已完成', ind: 3 }, { tit: '订单取消', ind: 4 }],
      active: 0,
      list: [],
      page: 1,
      finished: false,
      loading: false,
    }
  },
  created() {
    if (sessionStorage.getItem("orderlist")) {
      this.active = Number(sessionStorage.getItem("orderlist"))
    }
  },
  methods: {
    onClick(index) {
      sessionStorage.setItem("orderlist", index)
      this.page = 1
      this.list = []
      this.finished = false
      this.onLoad()
    },
    progressfun(data) {
      if (data > 0) {
        return Number(data / 5 * 100).toFixed(0)
      }
      return data
    },
    godel(val) {
      this.$router.push({ name: "orderdel", query: { id: val.id, type: this.type } })
    },
    onLoad() {
      var page = this.page++
      this.$post({
        url: '/app/orders/myOrders',
        data: {
          page,
          status: this.active,
        },
        success: (res) => {
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var ret = res.data.data
            if (page == 1) {
              this.list = ret
            } else {
              for (var x in ret) {
                this.list.push(ret[x])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
}
</script>

<style scoped lang='less'>
.index {
  width: 100%;
  height: auto;
  padding: 0 0 80px;
  /deep/.van-nav-bar__content {
    .van-nav-bar__left {
      padding-left: 0;
    }
  }
  /deep/.van-sticky {
    width: 100%;
    padding: 0 15px;
  }
  /deep/.van-tabs {
    width: 100%;
    border: 2px solid #B3A7AE;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    .van-tabs__nav {
      width: 100%;
      background: #117948;
    }
  }
  .listbox {
    width: 100%;
    padding: 10px 15px;
    .item {
      width: 100%;
      margin-bottom: 10px;
      background: rgba(11, 11, 11, .2);
      border-radius: 10px;
      padding: 15px 15px 0;
      .topbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        color: #F6BE9D;
        font-size: 14px;
        font-weight: 550;
        border-bottom: 1px solid #B3A7AE;
        p:nth-child(2) {
          color: #F6BE9D;
          font-size: 14px;
          font-weight: 550;
        }
      }
      .goodsbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        >img {
          width: 85px;
          height: 85px;
          border-radius: 6px;
        }
        .right {
          width: 70%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          .name {
            width: 100%;
            color: #F6BE9D;
            font-size: 15px;
            font-weight: 550;
            line-height: 1.5;
          }
          .price {
            width: 100%;
            color: #F6BE9D;
            font-size: 18px;
            font-weight: 550;
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
