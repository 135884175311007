<template>
  <div class='indexbox' :class="{ 'zhbg': lang == 'zh' }">
    <nav-bar title="分享好友" bg-color="none" />
    <div class="qrbox">
      <img :src="data.img" alt="">
      <p class="codet">{{ $t('分享链接') }}</p>
      <p class="code">{{ data.url }}</p>
      <van-button v-clipboard:copy="data.url" v-clipboard:success="onCopy" v-clipboard:error="onError" color="#FDF2E6"
        block>{{ $t('复制分享链接') }}</van-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      lang: ''
    }
  },
  methods: {
    onCopy: function () {
      this.$toast({
        message: this.$t('复制成功'),
        duration: 400,
      })
    },
    onError: function () {
      this.$toast({
        message: this.$t('复制失败'),
        duration: 400,
      })
    },

    onClickLeft() {
      this.$router.go(-1)
    },
    getdata() {
      this.$toast.loading({
        forbidClick: true,
      })
      this.$post({
        url: "/app/member/share",
        success: (res) => {
          this.$toast.clear()
          this.data = res.data
        },
        tip: () => { },
      })
    },
  },
  created() {
    this.getdata()
    if (this.local.get('lang')) {
      this.lang = this.local.get('lang')
    }
  }
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background: url('../../assets/img/yao4.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 80px;
  .qrbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: rgba(28, 114, 79, .9);
    box-shadow: inset 0px 0px 12px 0px #E1A763;
    border-radius: 20px 20px 20px 20px;
    padding: 20px 40px;
    img {
      width: 130px;
      height: 130px;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .codet {
      font-size: 14px;
      font-weight: 550;
      color: #fff;
      text-align: center;
    }
    .code {
      font-size: 15px;
      font-weight: 550;
      margin: 10px 0 20px;
      text-align: center;
    }
  }
}
.zhbg {
  background: url('../../assets/img/yao.png') no-repeat;
  background-size: 100% 100%;
}
</style>