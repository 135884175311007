<template>
  <div class='indexbox'>
    <nav-bar title="消息列表" left-arrow />
    <van-list v-model="loading" :finished="finished" :finished-text="`${$t('没有更多了')}`" @load="onLoad" :offset="0">
      <div class="listbox" v-for="val in list" :key="val.id"
        @click.prevent="$router.push({ name: 'newdel', query: { id: val.id } })">
        <div class="rightbox">
          <p class="name two">{{ val.title }}</p>
          <p class="time one">{{ val.create_time }}</p>
        </div>
        <img v-lazy="val.image" alt='' v-if="val.image" class="goodsimg">
      </div>
    </van-list>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  methods: {
    onLoad() {
      var page = this.page++
      this.$post({
        url: "/app/notice/pageList",
        data: {
          page,
        },
        success: (res) => {
          this.$toast.clear()
          if (!res.data || res.data.data.length == 0) {
            this.finished = true
          } else {
            var list = res.data.data
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>
<style lang='less' scoped>
.indexbox {
  width: 100%;
  padding: 10px 15px 20px;
  .listbox {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    background: rgba(28, 115, 80, 0.9);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #1C724F;
    img {
      width: 108px;
      height: 75px;
      border-radius: 6px;
    }
    .rightbox {
      width: 63%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .name {
        width: 100%;
        color: #fff;
        font-size: 15px;
        font-weight: 550;
      }
      .time {
        width: 100%;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}
</style>
