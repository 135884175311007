module.exports = {
  "首页": "首頁",
  "订单": "訂單",
  "个人": "個人",
  "DNA": "DNA",
  "权益专区": "權益專區",
  "精选好物": "精選好物",
  "健康服务": "健康服務",
  "特惠专区": "特惠專區",
  "公告": "公告",
  "精选商品": "精選商品",
  "立即购买": "立即購買",
  "没有更多了": "沒有更多了",
  "待付款": "待付款",
  "待确认": "待確認",
  "待核销": "待核銷",
  "已完成": "已完成",
  "订单取消": "訂單取消",
  "地址验证登录": "驗證地址登錄賬戶",
  "收益钱包": "收益錢包",
  "LP代币": "LP代幣",
  "我的订单": "我的訂單",
  "我的社区": "我的社區",
  "分享好友": "分享好友",
  "分享": "分享",
  "语言切换": "語言切換",
  "客服中心": "客服中心",
  "密码设置": "密碼設定",
  "商品详情": "商品詳情",
  "赠送": "贈送",
  "数量": "數量",
  "限时特惠": "限時特惠",
  "产品详情": "產品詳情",
  "立即购买": "立即購買",
  "请先设置安全密碼": "請先設定安全密碼",
  "输入支付密码": "輸入支付密碼",
  "价值": "價值",
  "提交": "提交",
  "立即支付": "立即支付",
  "成功": "成功",
  "失败": "失敗",
  "消息列表": "消息清單",
  "订单详情": "訂單詳情",
  "价格明细": "價格明細",
  "商品总价": "商品總價",
  "实际支付": "實際支付",
  "订单信息": "訂單資訊",
  "订单号": "訂單號",
  "创建时间": "創建時間",
  "付款时间": "付款時間",
  "复制成功": "複製成功",
  "复制失败": "複製失敗",
  "支付成功": "支付成功",
  "您已完成支付": "您已完成支付",
  "知道了": "知道了",
  "安全密碼": "安全密碼",
  "旧安全密碼": "舊安全密碼",
  "请输入旧安全密碼": "請輸入舊安全密碼",
  "重复密码": "重複密碼",
  "请输入重复安全密碼": "請輸入重複安全密碼",
  "确定": "確定",
  "两次安全密碼输入不一致": "兩次安全密碼輸入不一致",
  "请确认安全密碼": "請確認安全密碼",
  "客服留言": "客服留言",
  "记录": "記錄",
  "请输入留言": "請輸入留言",
  "发送": "發送",
  "反馈记录": "迴響記錄",
  "查看回复": "查看回復",
  "反馈详情": "迴響詳情",
  "回复": "回復",
  "系统回复": "系統回復",
  "分享链接": "分享鏈接",
  "复制分享链接": "複製分享鏈接",
  "复制成功": "複製成功",
  "复制失败": "複製失敗",
  "明细": "明細",
  "社区业绩": "業績",
  "社区人数": "社區",
  "直推人数": "直購",
  "社区会员列表": "社區會員列表",
  "密码设置": "密碼設定",
  "修改安全密碼": "修改安全密碼",
  "钱包明细": "錢包明細",
  "余额": "餘額",
  "提现": "提現",
  "余额变动明细": "餘額變動明細",
  "提现数量": "提現數量",
  "请输入提现数量": "請輸入提現數量",
  "手续费": "手續費",
  "最低提现数量": "起提數",
  "提现倍数": "提現倍數",
  "实际到账": "實際到賬",
  "请输入安全密碼": "請輸入安全密碼",
  "提现记录": "提現記錄",
  "待审核": "待稽核",
  "已通过": "已通過",
  "已驳回": "已駁回",
  "钱包余额不足": "錢包餘額不足",
  "取消订单": "取消訂單",
  "系统未设置收款钱包地址": "系統未設定收款錢包地址",
  "请地址验证登录": "請地址驗證登入",
  "钱包等待投放，暂时无法使用": "錢包等待投放，暫時無法使用",
  "请切换到": "請切換至以太坊ETH Ereum,幣安鏈BNB Chain的主網地址",
  "空投": "空投时间",
  "赠送": "贈送时间",
  "请复制您的链接在DAPP浏览器访问": "請複製您的連結在DAPP瀏覽器訪問",
  "暂未绑定上级": "暫未綁定上級，請先綁定",
  "请输入上级": "請輸入上級 UID",
  "请输入": "請輸入",
  "有效": "有效",
  "无效": "無效",
}